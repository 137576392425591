import { createContext, FC, useState } from 'react'
import { PapertrailClient } from '../../papertrail/PapertrailClient'
import { Source } from '../../papertrail/Source'

export type NullablePapertrailClient = PapertrailClient | null

type ConnectArgs = { token: string, source?: Source }

export interface PapertrailClientContextPayload {
    client: NullablePapertrailClient
    connect: (args: ConnectArgs) => void
    update: () => void
}

export const PapertrailClientContext = createContext<PapertrailClientContextPayload>(null as any)

export const PapertrailClientProvider: FC<{}> = (props) => {
    const [client, setClient] = useState<PapertrailClient | null>(null)

    const payload: PapertrailClientContextPayload = {
        client,
        connect: client ?
            () => {
                throw new Error('Papertrail client already connected')
            }
            :
            ({ token, source }) => {
                setClient(new PapertrailClient({ token, source }))
            },
        update: client ?
            () => {
                setClient(new PapertrailClient({ token: client.token, source: client.source }))
            }
            :
            () => {
                throw new Error('Papertrail client not set so cannot update')
            }
    }

    return (<>
        <PapertrailClientContext.Provider value={payload}>
            {props.children}
        </PapertrailClientContext.Provider>
    </>)
}