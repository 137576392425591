import { FC, useContext, useEffect, useRef, useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { ClauseStorageContext } from "../clause/ClauseStorageProvider"
import { PapertrailClientContext } from "../main/PapertrailClientProvider"
import { EventLine } from "./EventLine"
import { PapertrailResponse } from "../../papertrail/PapertrailResponse"
import { Time } from "../../helper/Time"

const LOG_LIMIT = 100
const MINIMUM_HOURS_BACK_TO_SEARCH_IF_NO_RESULTS = 2

interface QueryResultProps {
    severity_query?: string
}

export const QueryResult: FC<QueryResultProps> = ({ severity_query }) => {
    const ptctx = useContext(PapertrailClientContext), client = ptctx.client
    if (!client) return null

    const [is_updating, set_updating] = useState(false)

    const clctx = useContext(ClauseStorageContext)

    const [result, setResult] = useState<PapertrailResponse>()

    const refScroller = useRef<HTMLDivElement>(null)
    const scrollToTop = () => refScroller.current?.scrollTo({ top: 0 })
    const scrollToBottom = () => refScroller.current?.scrollTo({ top: refScroller.current.scrollHeight })
    const scrollUp = () => refScroller.current?.scrollBy({ top: -refScroller.current.clientHeight })
    const scrollDown = () => refScroller.current?.scrollBy({ top: refScroller.current.clientHeight })

    useHotkeys(',', scrollUp, [ptctx])
    useHotkeys('.', scrollDown, [ptctx])
    useHotkeys('SHIFT+,', scrollToTop, [ptctx])
    useHotkeys('SHIFT+.', scrollToBottom, [ptctx])

    const filter_query = clctx.filter_query()

    const ptquery = client.combined_query(severity_query, filter_query)
    useHotkeys('Q', () => alert(ptquery), [clctx, severity_query])
    useHotkeys('SHIFT+Q', () => { window.open(client.query_url(ptquery)) }, [clctx, severity_query])

    useEffect(() => {
        set_updating(true)
        client.query_latest_events(severity_query, filter_query, LOG_LIMIT, MINIMUM_HOURS_BACK_TO_SEARCH_IF_NO_RESULTS)
            .then(result => (set_updating(false), setResult(result)))
    }, [ptctx, clctx, severity_query])

    const events = result?.events

    return (
        <>
            {result && events && events.length ?
                <>
                    <p>{`${events.length} events back to ${new Time(result.min_time_at).papertrail_format}, ${new Time(new Date(events[0].received_at)).time} - ${new Time(new Date(events[events.length - 1].received_at)).time}`} {is_updating ? ' ... Updating' : ''}</p>
                    <div ref={refScroller} style={{ position: 'absolute', borderRadius: '20px 20px 0px 0px', borderTop: 'solid 2px white', top: '70px', bottom: '0px', left: '0px', right: '0px', overflow: 'auto' }}>
                        <p style={{ textAlign: 'center' }}>EARLIEST EVENT {events[0].id} - {events[0].display_received_at}</p>
                        {events?.map(e => <EventLine key={e.id} event={e} />)}
                        <p style={{ textAlign: 'center' }}>LATEST EVENT {events[events.length - 1].id} - {events[events.length - 1].display_received_at}</p>
                    </div>
                </>
                :
                <p>No events back to {result ? new Time(result?.min_time_at).papertrail_format : 'unknown'} {is_updating ? ' ... Updating' : ''}</p>
            }
        </>
    )
}

