import { createContext, FC, useContext } from 'react'
import { Clause } from './Clause'
import { ClauseCollection, ClauseStorageContext } from './ClauseStorageProvider'

interface ClauseFilterContextPayload {
    clauses: ClauseCollection
    statusFilter?: string
    typeFilter?: string
}

export const ClauseFilterContext = createContext<ClauseFilterContextPayload>(null as any)

export interface ClauseFilterProviderProps {
    statusFilter?: string
    typeFilter?: string
}

export const ClauseFilterProvider: FC<ClauseFilterProviderProps> = ({ statusFilter, typeFilter, children }) => {

    const ctx = useContext(ClauseStorageContext)

    const payload: ClauseFilterContextPayload = {
        clauses: ctx.clauses
            .filter((c: Clause) => !statusFilter || c.status.toUpperCase() === statusFilter)
            .filter((c: Clause) => !typeFilter || c.type.toUpperCase() === typeFilter),
        statusFilter,
        typeFilter
    }

    return (<>
        <ClauseFilterContext.Provider value={payload}>
            {children}
        </ClauseFilterContext.Provider>
    </>)
}
