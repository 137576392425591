import { createContext, FC, useContext } from 'react'
import { Clause } from './Clause'
import { PapertrailClientContext } from '../main/PapertrailClientProvider'
import { useClauses } from '../../hook/useClauses'

export type ClauseCollection = Clause[]

export interface ClauseContextPayload {
    clauses: ClauseCollection
    clear: () => void
    set: (clauses: ClauseCollection) => void
    add: (clause: Clause) => void
    remove: (clause: Clause) => void
    update: () => void
    filter_query: () => string
}

export const ClauseStorageContext = createContext<ClauseContextPayload>(null as any)

export const ClauseStorageProvider: FC<{}> = ({ children }) => {
    const ptctx = useContext(PapertrailClientContext), client = ptctx.client

    const { clauses, setClauses } = useClauses(client?.source)

    const payload: ClauseContextPayload = {
        clauses,
        clear: () => setClauses([]),
        set: cc => setClauses(cc),
        add: c => setClauses([c, ...clauses]),
        remove: c => setClauses(clauses.filter(x => x !== c)),
        update: () => setClauses([...clauses]),
        filter_query: () => clauses.reduce((accum, curr) => curr.disabled ? accum : `${accum} ${curr.negative_query}`, '')
    }
    
    return (<>
        <ClauseStorageContext.Provider value={payload}>
            {children}
        </ClauseStorageContext.Provider>
    </>)
}