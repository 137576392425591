import { Time } from '../helper/Time'
import { Group } from './Group'
import { PapertrailResponse } from './PapertrailResponse'
import { QueryParams } from './QueryParams'
import { System } from './System'
import { throttle } from '../helper/throttle'
import { Source, SourceType } from './Source'
import { Event } from './Event'

const MIN_LIMIT = 1
const MAX_LIMIT = 3000

const LOCALSTORAGE_KEY_ROOT = 'PapertrailClient'
const LOCALSTORAGE_KEY_SYSTEMS = `${LOCALSTORAGE_KEY_ROOT}_Systems`
const LOCALSTORAGE_KEY_GROUPS = `${LOCALSTORAGE_KEY_ROOT}_Groups`

const errorStuff = (prefix: string, context: string, message: string): void => {
    throw `${prefix} ${context} ERROR - ${message}`
}

const api_url_base = 'https://papertrailapp.com/api/v1'
const api_url_events = `${api_url_base}/events/search.json`
const api_url_systems = `${api_url_base}/systems.json`
const api_url_groups = `${api_url_base}/groups.json`

const sort_name = (a: { name: string }, b: { name: string }) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0

export interface PapertrailClientConfig {
    token?: string
    source?: Source
    limit?: number
}

const error_count: { [key_name: string]: number } = {}
// @ts-ignore
window.papertrail_errors = error_count

const main_load_fetch = throttle(fetch, 20, 5000)
const repeater_fetch = throttle(fetch, 5, 5000)

export class PapertrailClient {

    error(context: string, message: string) {
        errorStuff('PapertrailClient', context, message)
    }

    private _token: string
    private _limit: number
    private _source: Source | undefined

    constructor({ token, source, limit }: PapertrailClientConfig = {}) {
        this._token = token || ''
        this._source = source
        this._limit = limit || MAX_LIMIT
    }

    get token() {
        return this._token
    }

    set token(value: string) {
        this._token = value
    }

    get source() {
        return this._source
    }
    set source(value) {
        this._source = value
    }

    get limit() {
        return this._limit
    }

    set limit(value) {
        if (value < MIN_LIMIT) this.error('.limit set', `cannot set limit < ${MIN_LIMIT}`)
        if (value > MAX_LIMIT) this.error('.limit set', `cannot set limit > ${MAX_LIMIT}`)

        this._limit = value
    }

    async query(fetcher: Function, query_params: QueryParams = {}, show_alert_on_failure = false): Promise<PapertrailResponse | undefined> {
        const url = new URL(api_url_events)

        if (!this._source) throw new Error('Cannot query PapertrailClient without setting source')

        if (!query_params.tail) query_params.tail = false
        if (!query_params.limit) query_params.limit = this._limit

        if (this._source.type == SourceType.sys) {
            query_params.system_id = this._source.id
        } else {
            query_params.group_id = this._source.id
        }

        if (!url) { this.error('.query', 'missing url') }
        if (!this._token) { this.error('.query', 'missing token') }
        if (!query_params.system_id && !query_params.group_id) { this.error('.query', 'must provide either system_id or group_id') }

        // remove any search query params which are not explicitly set (truthy will do)
        let keys: (keyof QueryParams)[] = ['q', 'min_time', 'max_time', 'min_id', 'max_id']
        keys.forEach(k => query_params[k] || delete query_params[k])

        url.search = new URLSearchParams(Object.assign({} as any, query_params)).toString()

        let json: PapertrailResponse | undefined

        try {
            let resp = await fetcher(url.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Papertrail-Token': this._token
                },
            })
            if (resp.ok) {
                json = await resp.json()
            } else {
                error_count[resp.statusText] = (error_count[resp.statusText] || 0) + 1
            }
        } catch (ex) {
            console.log('ACTUAL QUERY ERROR! TO-DO...')
            console.log(ex)
        }

        return json
    }

    async count_hour_since(since: Date | Time, search_query: string = '') {
        if (!since) this.error('.query_hour', 'Must provide "since" timestamp')

        const since_timestamp = new Time(since).round_hour().timestamp

        let query_params: QueryParams = {
            min_time: since_timestamp,
            max_time: since_timestamp + 3599,
            q: search_query,
            tail: false,
        }

        let results = 0, completed: boolean = false

        let query_response
        do {
            query_response = await this.query(main_load_fetch, query_params)

            if (query_response && !query_response.error) {
                query_params = {
                    min_time: since_timestamp,
                    max_id: query_response.min_id - 1,
                    q: search_query,
                    tail: false,
                }

                results += query_response.events.length
            }

            completed = (query_response && !query_response.reached_record_limit && !query_response.reached_time_limit) ?? false
        } while (!completed)

        return results
    }

    combined_query(severity?: string, search_query?: string): string {
        return `${severity ? `severity:(${severity}) ` : ''} ${search_query || ''}`
    }

    async query_latest_events(severity?: string, search_query?: string, limit: number = 100, zero_results_hours_extension?: number) {
        const q = this.combined_query(severity, search_query)

        let aggregated_events: Event[] = []

        let query_params: QueryParams = {
            q,
            limit,
            tail: false,
        }

        let result = await this.query(repeater_fetch, query_params)
        aggregated_events = result?.events ?? []

        while (zero_results_hours_extension && result?.events?.length === 0 && result?.reached_time_limit && new Time(new Date(result?.min_time_at)).timestamp > Time.hours_ago(zero_results_hours_extension).timestamp) {
            console.log('Extending search')
            let query_params: QueryParams = {
                q,
                limit,
                tail: false,
                max_id: result.min_id
            }
            result = await this.query(repeater_fetch, query_params)
            aggregated_events = [...(result?.events ?? []), ...aggregated_events]
        }

        if (result) result.events = aggregated_events

        return result
    }

    #groups?: Promise<Group[]>
    #systems?: Promise<System[]>

    get groups(): Promise<Group[]> {
        if (!this.#groups && localStorage[LOCALSTORAGE_KEY_GROUPS]) {
            this.#groups = Promise.resolve(JSON.parse(localStorage[LOCALSTORAGE_KEY_GROUPS]).map(([id, name]: any) => ({ id, name })))
        }
        if (!this.#groups) {
            this.#groups = fetch(api_url_groups, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Papertrail-Token': this._token
                }
            })
                .then(response => response.json())
                .then((g: Group[]) => g.sort(sort_name))
                .then(dat => (localStorage[LOCALSTORAGE_KEY_GROUPS] = JSON.stringify(dat.map(x => [x.id, x.name])), dat))
        }
        return this.#groups
    }

    get systems(): Promise<System[]> {
        if (!this.#systems && localStorage[LOCALSTORAGE_KEY_SYSTEMS]) {
            this.#systems = Promise.resolve(JSON.parse(localStorage[LOCALSTORAGE_KEY_SYSTEMS]).map(([id, name]: any) => ({ id, name })))
        }
        if (!this.#systems) {
            this.#systems = fetch(api_url_systems, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Papertrail-Token': this._token
                }
            })
                .then(response => response.json())
                .then((sys: System[]) => sys.sort(sort_name))
                .then(dat => (localStorage[LOCALSTORAGE_KEY_SYSTEMS] = JSON.stringify(dat.map(x => [x.id, x.name])), dat))
        }
        return this.#systems
    }

    event_url(event_id: string) {
        if (!this._source) throw new Error('Cannot generate url without setting source')
        return `https://my.papertrailapp.com/${this._source.type == SourceType.sys ? 'systems' : 'groups'}/${this._source.id}/events${event_id ? `?focus=${event_id}&selected=${event_id}` : ''}`
    }

    query_url(query: string) {
        if (!this._source) throw new Error('Cannot generate url without setting source')
        return `https://my.papertrailapp.com/${this._source.type == SourceType.sys ? 'systems' : 'groups'}/${this._source.id}/events${query ? `?q=${encodeURIComponent(query)}` : ''}`
    }
}