import { useContext, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { ClauseCollection, ClauseStorageContext } from './ClauseStorageProvider'

export const CutCopyPaste = () => {
    const clctx = useContext(ClauseStorageContext)

    const [clauses_buffer, setClauses] = useState<ClauseCollection>()
    const [open, setOpen] = useState(false)

    const toggle = () => (setOpen(!open))
    const cut = () => (setClauses(clctx.clauses), clctx.clear())
    const copy = () => setClauses(clctx.clauses)
    const paste = () => clauses_buffer && clctx.set(clauses_buffer)

    const key_opts = { enabled: open }
    useHotkeys('Z', toggle, [open])
    useHotkeys('X', cut, key_opts, [clctx])
    useHotkeys('C', copy, key_opts, [clctx])
    useHotkeys('V', paste, key_opts, [clctx])

    return (<>
        {open ?
            <>
                <button title='Z' onClick={toggle}>&raquo;</button>
                <button title='X' className='red' onClick={cut}>X</button>
                <button title='C' className='red' onClick={copy}>C</button>
                <button title='V' className='red' onClick={paste}>V</button>
            </>
            :
            <button onClick={toggle}>&laquo;</button>
        }
    </>)
}
