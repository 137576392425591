import { useCallback, useContext } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { Clause } from "./Clause"
import { ClauseStorageContext } from "./ClauseStorageProvider"
import { SelectedClauseContext } from "./SelectedClauseProvider"

const prompt_new_clause = (suffix: string = '') => {
    let suggest = document.getSelection()?.toString();
    if (suggest) {
        suggest = `'${suggest.replace(/\\/g, '\\\\').replace(/'/g, "\\'")}'`;
    }
    let query = prompt("Enter your Papertrail Clause for filtering.\n\nRemember to quote 'multiple word phrases', and capitalise AND / OR logic.", `${suggest}${suffix}`);
    return query
}

export const New = () => {
    const clctx = useContext(ClauseStorageContext)
    const selctx = useContext(SelectedClauseContext)

    const add_clause = useCallback((suffix = '') => {
        let query = prompt_new_clause(suffix)
        if (query) {
            const clause = new Clause({ query })
            clctx.add(clause)
            selctx.setCurrentClause(clause)
        }
    }, [clctx, selctx])

    useHotkeys('N', () => add_clause(), [clctx])
    useHotkeys('SHIFT+N', () => add_clause(' \'\''), [clctx])
    useHotkeys('SHIFT+E', () => add_clause(' severity:error'), [clctx])
    useHotkeys('SHIFT+W', () => add_clause(' severity:warn'), [clctx])

    return (
        <button title='N' onClick={e => add_clause()}>&#10011;</button>
    )
}