import { FC, useContext, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { PapertrailClientContext } from '../main/PapertrailClientProvider'
import { QueryResult } from './QueryResult'

interface LogViewerProps {

}

const level_options = [
    { display: 'ERR', query: 'error', hotkey: 'E' },
    { display: 'WARN', query: 'error warn', hotkey: 'W' },
    { display: 'INFO', query: 'error warn info', hotkey: 'I' },
    { display: 'ALL', query: '', hotkey: 'A', default: true },
]

export const LogViewer: FC<LogViewerProps> = ({ }) => {
    const ptctx = useContext(PapertrailClientContext), client = ptctx.client

    const [severity, set_severity] = useState(level_options.find(o => o.default))
    level_options.forEach(opt => useHotkeys(opt.hotkey, () => set_severity(opt)), [ptctx])

    const refresh = () => ptctx.update()
    useHotkeys('R', refresh, [ptctx])

    if (!(client && client.source)) return null

    return (
        <div className='log-viewer'>
            {level_options.map(opt => <button key={opt.display} className={`wide ${severity && severity.display === opt.display ? 'active' : ''}`} onClick={() => set_severity(opt)}> {opt.display}</button>)}
            <button title='R' onClick={refresh}>&#8635;</button>

            <QueryResult severity_query={severity?.query} />
        </div>
    )
}