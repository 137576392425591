import React, { useContext } from 'react'
import { InputLocalStorage } from '../../component/InputLocalStorage'
import { PapertrailClientContext } from './PapertrailClientProvider'

export const ConnectDialog = () => {
    const ctx = useContext(PapertrailClientContext), client = ctx.client
    return (
        <div style={{ width: '400px', margin: '40px auto', padding: '40px', border: 'solid 2px #009999', borderRadius: '20px', textAlign: 'center', boxShadow: ' 0px 3px 6px rgba(0, 153, 153, 0.6)' }}>
            Connect to Papertrail API
            <br /><br />
            <InputLocalStorage label='Token' id='token' />
            <br /><br />
            <button className='extra-wide' onClick={e => ctx.connect({ token: localStorage.__input_token })}>Connect</button>
        </div>
    )
}