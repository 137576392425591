import { FC } from 'react'
import { SourceSelector } from './SourceSelector'
import { ClausesPanel } from './ClausesPanel'
import { LogViewer } from '../log/LogViewer'

export const Main: FC<{}> = () => {
    return (<>
        <SourceSelector />
        <div className='main'>
            <LogViewer>

            </LogViewer>
            <ClausesPanel />
        </div>
    </>)
}