import { createContext, useState, useEffect, FunctionComponent, FC } from 'react'

export const HeartbeatContext = createContext(0)

interface HeartbeatProviderProps {
    trigger_milliseconds: number
}

export const HeartbeatProvider: FC<HeartbeatProviderProps> = (props) => {

    const [count, set_count] = useState(0)

    useEffect(() => {
        const timeout = setTimeout(_ => {
            set_count(count + 1)
        }, props.trigger_milliseconds)
        return (() => clearTimeout(timeout))
    })

    return (
        <HeartbeatContext.Provider value={count}>
            {props.children}
        </HeartbeatContext.Provider>
    )
}