import { FC, useContext, useEffect, useState } from 'react'
import { PapertrailClientContext, PapertrailClientProvider } from './main/PapertrailClientProvider'
import { ClauseStorageProvider } from './clause/ClauseStorageProvider'
import { ConnectDialog } from './main/ConnectDialog'
import { Main } from './main/Main'
import { HeartbeatProvider } from '../component/HeartbeatProvider'
import { Clock } from '../component/Clock'
import { AppName } from './AppName'
import { Help } from './main/Help'
import './app.css'

export const App = () => (
    <>
        <AppName />
        <PapertrailClientProvider>
            <ClauseStorageProvider>
                <ClientConnectSwitcher />
            </ClauseStorageProvider>
            <ClientLeaker />
        </PapertrailClientProvider>
        <Help />
    </>
)

const ClientConnectSwitcher: FC<{}> = () => {
    const ctx = useContext(PapertrailClientContext), client = ctx.client
    return (<>
        <HeartbeatProvider trigger_milliseconds={1000}>
            <Clock styles={{ position: 'absolute', top: '10px', right: '10px' }} />
        </HeartbeatProvider>
        {
            !client ?
                <ConnectDialog />
                :
                <Main />
        }
    </>)
}

const ClientLeaker = () => {
    const ctx = useContext(PapertrailClientContext), client = ctx.client
    console.log(client)
    return null
}