import { useCallback, useState } from 'react'
import { Clause } from '../app/clause/Clause'
import { ClauseCollection } from '../app/clause/ClauseStorageProvider'
import { Source } from '../papertrail/Source'

export const useClauses = (source?: Source) => {
    let [clauses, dummySetClauses] = useState<ClauseCollection>([])

    const storage_key = useCallback((id: string) => `PT2CLAUSES_${id}`, [])

    const load = useCallback((id: string): ClauseCollection => {
        const raw = JSON.parse(localStorage[storage_key(id)] || '[]')
        return raw.map((r: any) => new Clause({ query: r._query, status: r._status, type: r._type, disabled: r._disabled }))
    }, [])

    const save = useCallback((id: string, clauses: ClauseCollection) => {
        localStorage[storage_key(id)] = JSON.stringify(clauses)
    }, [])

    clauses = source ? load(source.id) : []

    const setClauses = (cc: ClauseCollection) => {
        if (source) save(source.id, cc)
        dummySetClauses([])
    }

    return ({
        clauses,
        setClauses,
    })
}