import { useContext } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { PapertrailClientContext } from '../main/PapertrailClientProvider'
import { ActivityGraph } from './ActivityGraph'
import { Clause, Status } from './Clause'
import { ClauseStorageContext } from './ClauseStorageProvider'
import { SelectedClauseContext } from './SelectedClauseProvider'

interface ClauseLineProps {
    clause: Clause
    showActivityGraph: boolean
}

export const ClauseItem = ({ clause, showActivityGraph }: ClauseLineProps) => {
    const ptctx = useContext(PapertrailClientContext)

    const clctx = useContext(ClauseStorageContext)
    const selectedctx = useContext(SelectedClauseContext)

    const set_new = () => (clause.status = Status.NEW, clctx.update())
    const set_concern = () => (clause.status = Status.CONCERN, clctx.update())
    const set_monitor = () => (clause.status = Status.MONITOR, clctx.update())
    const set_ignore = () => (clause.status = Status.IGNORE, clctx.update())
    const delete_clause = () => (clctx.remove(clause))
    const toggle_disabled = () => (clause.disabled = !clause.disabled, console.log(clause), clctx.update())
    const is_current = selectedctx.clause?.search_query == clause.search_query

    const key_opts = { enabled: is_current }
    useHotkeys('-', set_new, key_opts, [clause])
    useHotkeys('=', set_concern, key_opts, [clause])
    useHotkeys('[', set_monitor, key_opts, [clause])
    useHotkeys(']', set_ignore, key_opts, [clause])
    useHotkeys('\'', toggle_disabled, key_opts, [clause])
    useHotkeys('Backspace', delete_clause, { enabled: is_current && clause.status === Status.NEW }, [clause])

    const launch_query_in_papertrail = () => { window.open(ptctx.client!.query_url(clause.search_query)) }
    useHotkeys('P', launch_query_in_papertrail, key_opts, [clause])

    return (<div className={`clause ${is_current ? 'current' : ''}`}>
        <div style={{ float: 'left', marginRight: '10px' }}>
            <a style={{ color: clause.disabled ? '#f00' : '#444', cursor: 'not-allowed' }} onClick={toggle_disabled}>&#x2689;</a>
        </div>
        <div style={{ float: 'right', position: 'relative', top: '-4px', marginLeft: '6px' }}>
            <a style={{ color: '#666', cursor: 'alias' }} onClick={launch_query_in_papertrail}>&#9755;</a>
            &nbsp;
            {clause.status !== Status.NEW ? null : <button className='red' style={{ transform: 'scale(0.7)' }} onClick={delete_clause}>X</button>}
        </div>
        <div style={{ lineBreak: 'anywhere', overflow: 'hidden' }}>
            {clause.search_query}
        </div>
        <br />
        <div style={{ float: 'right', position: 'relative', top: '-6px' }}>
            {clause.status === Status.NEW ? null : <button onClick={set_new}>?</button>}
            {clause.status === Status.CONCERN ? null : <button onClick={set_concern}>!</button>}
            {clause.status === Status.MONITOR ? null : <button onClick={set_monitor}>...</button>}
            {clause.status === Status.IGNORE ? null : <button onClick={set_ignore}>.</button>}
        </div>
        {showActivityGraph ? <ActivityGraph key={clause.search_query} query={clause.search_query} /> : null}
    </div>)
}