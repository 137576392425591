import React, { useContext } from 'react'
import { ClauseFilterProvider } from '../clause/ClauseFilterProvider'
import { FilterList } from '../clause/FilterList'
import { PapertrailClientContext } from './PapertrailClientProvider'
import { Status } from '../clause/Clause'
import { CutCopyPaste } from '../clause/CutCopyPaste'
import { SelectedClauseProvider } from '../clause/SelectedClauseProvider'

export const ClausesPanel = () => {
    const ptctx = useContext(PapertrailClientContext), client = ptctx.client

    if (!(client && client.source)) return null

    return (
        <div className='clauses'>
            <div className='header'>
                <h3>
                    {client.source.name}
                </h3>
                <div style={{ float: 'right' }}>
                    <CutCopyPaste />
                </div>
                <p>{client.source.type}:{client.source.id}</p>
            </div>
            <div className='clause-lists'>
                <SelectedClauseProvider>
                    <FilterAndList status={Status.NEW} />
                    <FilterAndList status={Status.CONCERN} />
                    <FilterAndList status={Status.MONITOR} />
                    <FilterAndList status={Status.IGNORE} />
                </SelectedClauseProvider>
            </div>
        </div>
    )
}

const FilterAndList = ({ status }: { status: Status }) => (
    <ClauseFilterProvider statusFilter={status}>
        <FilterList title={status} />
    </ClauseFilterProvider>
)