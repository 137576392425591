import { useEffect, useState } from "react"

const APP_NAME_CHANGE_TIMEOUT = 10000
const BIAS_TOWARDS_REAL_TITLE = 5

const LOGS = '<span style="color: red">L&#10084;gs</span>'

const APP_NAMES = [
    { name: 'papertrail<br/>assist <span style="color: red">&beta;</span>' },
    { name: `${LOGS},<br/>In Itself`, url: `https://www.youtube.com/watch?v=1pi_egc6qkY&ab_channel=DepecheMode` },
    { name: `You Give ${LOGS}<br/>A Bad Name`, url: `https://www.youtube.com/watch?v=KrZHPOeOxQQ&ab_channel=BonJoviVEVO` },
    { name: `I\'d Do Anything<br/>For ${LOGS}`, url: `https://www.youtube.com/watch?v=9X_ViIPA-Gc&ab_channel=MeatLoafVEVO` },
    { name: `Can You Feel<br/>The ${LOGS} Tonight`, url: `https://www.youtube.com/watch?v=DZr-VTULYQ8&ab_channel=DisneyMusicVEVO` },
    { name: `All You Need<br/>Is ${LOGS}`, url: `https://www.youtube.com/watch?v=_7xMfIp-irg&ab_channel=TheBeatles-Topic` },
    { name: `I Wanna Know<br/>What ${LOGS} Is`, url: `https://www.youtube.com/watch?v=r3Pr1_v7hsw&ab_channel=RHINO` },
    { name: `What\'s ${LOGS}<br/>Got To Do With It`, url: `https://www.youtube.com/watch?v=oGpFcHTxjZs&ab_channel=TinaTurnerOfficial` },
    { name: `The Power<br/>Of ${LOGS}`, url: `https://www.youtube.com/watch?v=b_zHQ6kFuQ0&ab_channel=JenniferrushVEVO` },
    { name: `How Deep Is<br/>Your ${LOGS}`, url: `https://www.youtube.com/watch?v=XpqqjU7u5Yc&ab_channel=beegees` },
    { name: `Kill This<br/>${LOGS}`, url: `https://www.youtube.com/watch?v=2S24-y0Ij3Y&ab_channel=BLACKPINK` },
    { name: `Crazy Little Thing<br/>Called ${LOGS}`, url: `https://www.youtube.com/watch?v=zO6D_BAuYCI&ab_channel=QueenOfficial` },
    { name: `Endless<br/>${LOGS}`, url: `https://www.youtube.com/watch?v=7Bwwo7ctG10&ab_channel=RETROVISOR` },
    { name: `I Believe In A Thing<br>Called ${LOGS}`, url: `https://www.youtube.com/watch?v=tKjZuykKY1I&ab_channel=RHINO` },
    { name: `Addicted To<br/>${LOGS}`, url: `https://www.youtube.com/watch?v=XcATvu5f9vE&ab_channel=RobertPalmerVEVO` },
    { name: `Whole Lotta<br/>${LOGS}`, url: `https://www.youtube.com/watch?v=HQmmM_qwG4k&ab_channel=LedZeppelin` },
    { name: `Tainted<br/>${LOGS}`, url: `https://www.youtube.com/watch?v=XZVpR3Pk-r8&ab_channel=SoftCellVEVO` },
    { name: `The End<br/>Of ${LOGS}`, url: `https://www.youtube.com/watch?v=jRFipSmLKfs&ab_channel=Nightly` },
    { name: `${LOGS} Hurt<br/>Bleed`, url: `https://www.youtube.com/watch?v=46kXvXV42Qk&ab_channel=GaryNumanVEVO`}
]

export const AppName = () => {

    const [idx, set_idx] = useState(0)

    useEffect(() => {
        let next_idx: number = idx
        while (next_idx === idx) {
            next_idx = Math.max(0, Math.floor(Math.random() * APP_NAMES.length + BIAS_TOWARDS_REAL_TITLE) - BIAS_TOWARDS_REAL_TITLE)
        }
        setTimeout(() => set_idx(next_idx), APP_NAME_CHANGE_TIMEOUT)
    })

    const app_name = APP_NAMES[idx]

    return (
        <h2 style={{ position: 'absolute', top: '10px', left: '20px', margin: '0px', textAlign: 'center', textShadow: '0px 2px 6px rgb(0, 153, 153, 1)' }}>
            <a href={app_name.url || '#'} target="the_music_of_logs">
                <span dangerouslySetInnerHTML={{ __html: app_name.name }} />
            </a>
        </h2>
    )
}
