import { useCallback, useContext, useEffect, useRef, useState, KeyboardEvent } from 'react'
import { PapertrailClientContext } from './PapertrailClientProvider'
import { Source, SourceType } from '../../papertrail/Source'
import { cache } from '../../papertrail/PapertrailCache'
import { useHotkeys } from 'react-hotkeys-hook'

const LOCALSTORAGE_SOURCEFILTER_KEY = '__sourcefilter'
const SELECTOR_HIDE_TIMEOUT = 1000

interface Option {
    id: string | number
    name: string
}

interface SourceSelectorListProps {
    filter: string,
    label: string,
    hotkey: string,
    datasource: Promise<Option[]>,
    type: SourceType,
    setSource: (src: Source) => void
}

const SourceSelectorList = ({ filter, label, hotkey, datasource, type, setSource }: SourceSelectorListProps) => {
    const option_loading: Option[] = [{ id: '', name: 'loading...' }]
    const option_select: Option = { id: '', name: 'select...' }
    const [listItems, setItems] = useState(option_loading)

    const el = useRef<HTMLSelectElement>(null)

    useHotkeys(hotkey, () => el.current?.focus())

    useEffect(
        () => {
            const rx = new RegExp(filter, 'i')
            datasource.then(r => r.filter(x => !filter || x.name.match(rx))).then(r => setItems([option_select, ...r]))
        },
        [filter]
    )

    return (<>
        {label}<select ref={el} size={1} onKeyDown={e => e.key == 'Escape' && (e.target as HTMLSelectElement).blur()} onChange={e => { setSource({ type, id: e.target.value, name: e.target.selectedOptions[0].text }); e.target.value = ''; e.target.blur() }} style={{ verticalAlign: 'top' }}>
            {listItems.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
        </select>
    </>)
}

export const SourceSelector = () => {
    const ctx = useContext(PapertrailClientContext), client = ctx.client
    if (!client) return null

    const [filter, setFilter] = useState(localStorage[LOCALSTORAGE_SOURCEFILTER_KEY] || '')
    const [dimmed, setDimmed] = useState(false)

    localStorage[LOCALSTORAGE_SOURCEFILTER_KEY] = filter

    const update_source = useCallback((src: Source) => {
        setDimmed(true)
        ctx.client!.source = src
        ctx.update()
    }, [ctx])

    const refFilter = useRef<HTMLInputElement>(null)

    useHotkeys('F', () => (setDimmed(false), refFilter?.current?.focus()), { keyup: true, enableOnTags: ['SELECT'] })

    const filter_keydown = (e: KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Enter':
                setFilter((e.target as HTMLInputElement).value)
                break
            case 'Escape':
                (e.target as HTMLInputElement).blur()
                setDimmed(true)
                break
        }
    }

    let timeout: NodeJS.Timeout

    return (
        <div style={{ textAlign: 'center', padding: '8px 90px', transition: 'opacity 0.5s', opacity: dimmed ? '0.3' : '1.0' }}
            onMouseEnter={e => setDimmed(false)}
            onMouseOver={e => timeout && clearTimeout(timeout)}
            onMouseLeave={e => timeout = setTimeout(() => setDimmed(true), SELECTOR_HIDE_TIMEOUT)}
        >
            Filter: <input id='source-filter' ref={refFilter} defaultValue={filter} onKeyDown={filter_keydown} />
            &nbsp; &nbsp;
            <SourceSelectorList filter={filter} label='Systems:' hotkey='S' datasource={client.systems} type={SourceType.sys} setSource={update_source} />
            &nbsp; &nbsp;
            <SourceSelectorList filter={filter} label='Groups:' hotkey='G' datasource={client.groups} type={SourceType.grp} setSource={update_source} />
            &nbsp; &nbsp;
            Cache: <button className='wide' onClick={e => cache.save()}>Save</button> <button className='wide red' onClick={e => cache.clear()}>Clear</button>
        </div>
    )
}