import { Time } from '../helper/Time'

const CACHE_LOCALSTORAGE_KEY = 'PT_CACHE'
const MAX_STORAGE_SIZE_FOR_CACHE = 5000000

type cache_datatype = number | undefined | Promise<number | undefined>

type cache_structure = {
    [key_source: string]: {
        [key_query: string]: {
            [key_timestamp: number]: cache_datatype
        }
    }
}

let _cache: cache_structure = {}

const get = (source: string, query: string, timestamp: number): cache_datatype => {
    ensure_path(source, query)
    const cached_value = _cache[source][query][timestamp]
    // If we have an object which is not a Promise then our cache has been deserialized and included some dead Promises
    if (typeof (cached_value) === 'object' && !(cached_value instanceof Promise)) return undefined
    return cached_value
}

const set = (source: string, query: string, timestamp: number, datum: cache_datatype) => {
    if (is_cache_candidate(timestamp)) {
        ensure_path(source, query)
        _cache[source][query][timestamp] = datum
    }
}

const ensure_path = (source: string, query: string): void => {
    if (!_cache[source]) {
        _cache[source] = {}
    }
    if (!_cache[source][query]) {
        _cache[source][query] = {}
    }
}

const is_cache_candidate = (timestamp: number): boolean => {
    const candidate_cutoff = Time.now().round_hour().timestamp
    return timestamp < candidate_cutoff
}

const save = () => {
    const stringified = JSON.stringify(_cache)
    if (stringified.length > MAX_STORAGE_SIZE_FOR_CACHE) {
        console.warn(`Unable to save cache as the stringified length ${stringified.length} is too long`)
    } else {
        localStorage[CACHE_LOCALSTORAGE_KEY] = stringified
    }
}

const load = () => {
    _cache = JSON.parse(localStorage[CACHE_LOCALSTORAGE_KEY] || '{}')
}

const clear = () => {
    _cache = {}
}

export const cache = {
    get,
    set,
    save,
    load,
    clear,
}

load()