export enum Status {
    NEW = 'NEW',
    CONCERN = 'CONCERN',
    MONITOR = 'MONITOR',
    KNOWN = 'KNOWN',
    IGNORE = 'IGNORE',
}

export enum Type {
    AUTO = 'AUTO',
    MANUAL = 'MANUAL',
    IMPORT = 'IMPORT',
}

export class Clause {
    _query: string
    _status: Status
    _type: Type
    _disabled: boolean = false

    constructor({ query = '', status = Status.NEW, type = Type.MANUAL, disabled = false } = {}) {
        if (!query) throw 'ERROR: Clause constructor - must provide "query".'
        this._query = query
        this._status = status
        this._type = type
        this._disabled = disabled
    }

    get type() {
        return this._type
    }
    
    get status() {
        return this._status
    }

    set status(v) {
        this._status = v
    }

    get search_query() {
        return this._query
    }

    get negative_query() {
        return `-(${this._query})`
    }

    get disabled() {
        return this._disabled
    }

    set disabled(v) {
        this._disabled = v
    }
}
