import { createContext, FC, useContext, useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { Clause, Status } from "./Clause"
import { ClauseStorageContext } from "./ClauseStorageProvider"

const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

interface SelectedClauseContextPayload {
    clause?: Clause
    setCurrentClause: (clause: Clause) => void
}

export const SelectedClauseContext = createContext<SelectedClauseContextPayload>({} as SelectedClauseContextPayload)

export const SelectedClauseProvider: FC<{}> = ({ children }) => {
    const clausesctx = useContext(ClauseStorageContext)

    const [clause, setCurrentClause] = useState<Clause | undefined>(undefined)

    const candidates = clausesctx.clauses.filter(c => c.status == Status.NEW)

    useHotkeys('Esc', () => setCurrentClause(undefined))

    for (let i = 0; i < keys.length; i++) {
        useHotkeys(keys[i], () => setCurrentClause(candidates[i]), { enabled: i < candidates.length }, [clausesctx])
    }

    return (
        <SelectedClauseContext.Provider value={{ clause, setCurrentClause }}>
            {children}
        </SelectedClauseContext.Provider>
    )
}

export const SelectionSpy = () => {
    const sel = useContext(SelectedClauseContext)

    console.log(`Selected clause = ${sel.clause?.search_query}`)

    return null
}