import { CSSProperties, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

/*

    F
    S
    G
    ESC

    E
    W
    I
    A
    R

    ,
    .
    SHIFT + ,
    SHIFT + .

    N
    SHIFT + N
    SHIFT + E
    SHIFT + W

    Z
    X
    C
    V

    1 - 0
    -
    =
    [
    ]
    P
    BACKSPACE
    '
    ESC

    Q
    SHIFT + Q

*/

const screen_style: CSSProperties = {
    display: 'block',
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
}

const dialog_style: CSSProperties = {
    display: 'block',
    position: 'absolute',
    top: '10%',
    left: '25%',
    right: '25%',
    bottom: '10%',
    backgroundColor: '#222',
    border: 'solid 4px white',
    borderRadius: '20px',
    padding: '20px 40px',
    overflow: 'auto',
}

export const Help = () => {
    const [show, setShow] = useState(false)

    useHotkeys('/', () => setShow(!show), [show])

    if (!show) return null

    return (
        <div style={screen_style}>
            <div style={dialog_style}>
                <KeysTable />
            </div>
        </div>
    )
}

const section_style: CSSProperties = {
    color: '#0ff',
    fontWeight: 'bold',
    fontSize: '1.2em',
}

const KeysTable = () => {
    return (<>
        <table style={{ width: '100%' }} cellSpacing='6'>
            <tr style={section_style}><td></td><td>SOURCE</td></tr>
            <tr><td>F</td><td>focus the sources Filter textbox</td><td>ESC</td><td>de-focus</td></tr>
            <tr><td>S</td><td>focus the Systems list</td><td>G</td><td>focus the Groups list</td></tr>
            <tr><td>&nbsp;</td></tr>
            <tr style={section_style}><td></td><td>LOG EVENTS</td></tr>
            <tr><td>E</td><td>List only Error events</td><td>,</td><td>Scroll up (earlier events)</td></tr>
            <tr><td>W</td><td>List Error + Warn events</td><td>.</td><td>Scroll down (later events)</td></tr>
            <tr><td>I</td><td>List Error + Warn + Info events</td><td>SHIFT + ,</td><td>Scroll to earliest event</td></tr>
            <tr><td>A</td><td>List All events</td><td>SHIFT + .</td><td>Scroll to latest event</td></tr>
            <tr><td>R</td><td>Refresh events list</td></tr>
            <tr><td>&nbsp;</td></tr>
            <tr style={section_style}><td></td><td>CLAUSES</td></tr>
            <tr><td>N</td><td>New clause (suggest selected text)</td><td>Z</td><td>Enable clause Cut/Copy/Paste controls</td></tr>
            <tr><td>SHIFT + N</td><td>New clause (with additional AND quotes)</td><td>X</td><td>Cut all clauses</td></tr>
            <tr><td>SHIFT + E</td><td>New clause (with severity:error)</td><td>C</td><td>Copy all clauses</td></tr>
            <tr><td>SHIFT + W</td><td>New clause (with severity:warn</td><td>V</td><td>Paste clauses</td></tr>
            <tr><td>&nbsp;</td></tr>
            <tr style={section_style}><td></td><td>CURRENT CLAUSE</td></tr>
            <tr><td>1 - 0</td><td>Select from first 10 'NEW' clauses</td><td>-</td><td>Move clause to NEW</td></tr>
            <tr><td>P</td><td>Open clause query in Papertrail</td><td>=</td><td>Move clause to CONCERN</td></tr>
            <tr><td>BACKSPACE</td><td>Delete the selected clause</td><td>[</td><td>Move clause to MONITOR</td></tr>
            <tr><td>'</td><td>Toggle filtering</td><td>]</td><td>Move clause to IGNORE</td></tr>
            <tr><td>ESC</td><td>Deselect</td></tr>
            <tr><td>&nbsp;</td></tr>
            <tr style={section_style}><td></td><td>FILTER QUERY</td></tr>
            <tr><td>Q</td><td>Show the filter Query</td><td>SHIFT + Q</td><td>Launch the filter Query in Papertrail</td></tr>
        </table>
    </>)
}