import { useContext, useMemo } from 'react';
import { Event } from '../../papertrail/Event'
import { NullablePapertrailClient, PapertrailClientContext } from '../main/PapertrailClientProvider';

const divider = ' ... '

const render_event = (event: Event, ptclient: NullablePapertrailClient) => {
    const message = event.message;
    let obj = null;
    try {
        obj = JSON.parse(message);
    } catch (ex) { /*NOP*/ }

    let msg_htm = obj ? JSON.stringify(obj, null, 4) : message.replace(/(\t+| {4}|\\r\\n|\\n|\r\n)/g, '\n');

    return (
        <>
            <b>
                {event.display_received_at}
                {divider}
                {/* {event.received_at} */}
                {/* {divider} */}
                {event.severity}
            </b>
            {divider}
            {event.hostname}
            {divider}
            {event.program}
            {divider}
            {
                ptclient ?
                <>[<a target='_blank' href={ptclient.event_url(event.id)}>{event.id}</a>]</>
                :
                <>[{event.id}]</>
            }
            <pre>{msg_htm}</pre>
        </>
    )
}

export const EventLine = ({ event }: { event: Event }) => {
    const ptctx = useContext(PapertrailClientContext)
    const render = useMemo(() => render_event(event, ptctx.client), [event.id])
    return (
        <>
            <div className={`event ${event.severity}`}>
                {render}
            </div>
        </>
    )
}