import { CSSProperties, FC, useContext } from 'react'
import { HeartbeatContext } from './HeartbeatProvider'

interface ClockProps {
    className?: string
    styles?: CSSProperties
}

export const Clock: FC<ClockProps> = ({ styles, className }) => {
    const counter = useContext(HeartbeatContext)
    return (
        <div className={className} style={styles}>
            {new Date().toUTCString()}
        </div>
    )
}