import { CSSProperties, FC, useContext, useEffect, useReducer, useState } from 'react'
import { Time } from '../../helper/Time'
import { PapertrailClientContext } from '../main/PapertrailClientProvider'
import { usePapertrailData } from '../../hook/usePapertrailData'

const NUMBER_OF_HOURS = 48

const initialThresholdColours = {
    THRESHOLD_1: 5,
    THRESHOLD_2: 10,
    THRESHOLD_3: 50,
    THRESHOLD_4: 200,
    THRESHOLD_5: 500,
    COL_COUNTING: '#ff00ff',
    COL_ZERO: '#333333',
    COL_1: '#008000',
    COL_2: '#00FF00',
    COL_3: '#80FF00',
    COL_4: '#FFFF00',
    COL_5: '#FF8000',
    COL_6: '#FF0000',
}

let colours = initialThresholdColours

export const setColouring = (c: typeof initialThresholdColours) => {
    colours = c || initialThresholdColours
}

const widget_style: CSSProperties = {
    display: 'inline-block',
}

const colour = (val: number | undefined): string => {
    if (typeof (val) === 'undefined') return colours.COL_COUNTING
    if (val === 0) return colours.COL_ZERO
    if (val < colours.THRESHOLD_1) return colours.COL_1
    if (val < colours.THRESHOLD_2) return colours.COL_2
    if (val < colours.THRESHOLD_3) return colours.COL_3
    if (val < colours.THRESHOLD_4) return colours.COL_4
    if (val < colours.THRESHOLD_5) return colours.COL_5
    return colours.COL_6
}

interface HourProps {
    query: string
    hour: Time
}

const Hour: FC<HourProps> = ({ query, hour }) => {
    const ctx = useContext(PapertrailClientContext), client = ctx.client
    if (!client) return null
    const value = usePapertrailData(client, query, hour)
    return (
        <li
            style={{ backgroundColor: (typeof (value) === 'undefined') ? colours.COL_COUNTING : colour(value) }}
            title={`${hour.iso_utc.substr(0, 16)} = ${value?.toString() ?? '...'}`}
            data-time={hour.iso_utc.substr(0, 16)}
            data-value={value}
        />
    )
}

export interface ActivityGraphProps {
    query: string
}

export const ActivityGraph: FC<ActivityGraphProps> = ({ query }) => {
    const ctx = useContext(PapertrailClientContext), client = ctx.client

    if (!client || !client.source) return null
    return (
        <div key={`${client.source.id}-${query}`} style={widget_style}>
            <ul>
                {range(NUMBER_OF_HOURS).map(h => <Hour key={Time.hours_ago(h).round_hour().iso_utc} hour={Time.hours_ago(h).round_hour()} query={query} />)}
            </ul>
            <Total query={query} />
        </div>
    )
}

const range = (size: number): number[] => new Array(size).join('.').split('.').map((_, idx) => idx)

enum Status {
    'OK'= 'OK',
    'IFFY' = 'IFFY',
    'BAD' = 'BAD',
}

const total_styles = {
    [Status.OK]: { color: 'white', backgroundColor: 'black' },
    [Status.IFFY]: { color: 'black', backgroundColor: 'yellow' },
    [Status.BAD]: { color: 'white', backgroundColor: 'red' },
}

const Total = ({ query }: { query: string }) => {
    const ctx = useContext(PapertrailClientContext), client = ctx.client

    const hrs = range(NUMBER_OF_HOURS).map(h => Time.hours_ago(h).round_hour())
    const hrs_most_recent = hrs.splice(0, 2)

    const subtotal_most_recent = hrs_most_recent.reduce((acc, cur) => acc + (usePapertrailData(client!, query, cur) || 0), 0)
    const subtotal_the_rest = hrs.reduce((acc, cur) => acc + (usePapertrailData(client!, query, cur) || 0), 0)

    const total = subtotal_the_rest + subtotal_most_recent

    const status = subtotal_most_recent <= 3 * (subtotal_the_rest / hrs.length) ? Status.OK : subtotal_the_rest > 0 ? Status.IFFY : Status.BAD

    return (<>
        <span style={total_styles[status]}>
            &nbsp;{total}&nbsp;
        </span>
    </>)
}