import { FC, useContext, useState } from 'react'
import { Status } from './Clause'
import { ClauseFilterContext } from './ClauseFilterProvider'
import { ClauseItem } from './ClauseItem'
import { New } from './New'

interface ClauseListProps {
    title: string
}

export const FilterList: FC<ClauseListProps> = ({ title }) => {
    const filtered = useContext(ClauseFilterContext)

    const [expand, setExpand] = useState(filtered.statusFilter !== Status.IGNORE)

    return (
        <div style={{ display: 'block', position: 'relative' }}>
            <h3>
                {
                    filtered.statusFilter === Status.NEW ?
                        <New />
                        :
                        <button className='' onClick={e => setExpand(!expand)}>
                            {expand ? '_' : filtered.clauses?.length?.toString() ?? '|'}
                        </button>
                }
                {title}
            </h3>
            <div style={{ display: expand ? 'block' : 'none' }}>
                {filtered.clauses.map(c => <ClauseItem key={c.search_query} clause={c} showActivityGraph={c.status !== Status.IGNORE} />)}
            </div>
        </div>
    )
}