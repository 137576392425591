
export enum SourceType {
    'sys' = 'sys',
    'grp' = 'grp',
}

export interface Source {
    type: SourceType
    id: string
    name: string
}
