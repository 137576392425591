import { CSSProperties, FC, } from 'react'

const labelStyle: CSSProperties = {
    fontSize: '0.8em',
    fontFamily: 'sans-serif',
    fontWeight: 'bold'
}

const inputStyle: CSSProperties = {
    padding: '6px 12px',
    borderRadius: '4px',
    border: 'solid 1px #999'
}

export interface InputLocalStorageProps {
    label: string
    id: string
}

export const InputLocalStorage: FC<InputLocalStorageProps> = props => {
    const key = `__input_${props.id}`
    return (<>
        <label htmlFor={props.id} style={labelStyle}>{props.label}</label>
        &nbsp;
        <input id={props.id} type='text' defaultValue={localStorage[key]} onChange={e => localStorage[key] = e.target.value} style={inputStyle} />
    </>)
}
