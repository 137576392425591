import { useEffect, useState } from 'react'
import { Time } from '../helper/Time'
import { PapertrailClient } from '../papertrail/PapertrailClient'
import { cache } from '../papertrail/PapertrailCache'

export const usePapertrailData = (client: PapertrailClient | undefined, query: string, hour: Time): number | undefined => {
    if (!client || !client.source) return undefined

    let [value, setValue] = useState<number | undefined>()
    const source_id = client.source.id
    const timestamp = hour.timestamp

    useEffect(() => {
        let cancel_update = false
        let data_or_promise = cache.get(source_id, query, timestamp)
        if (typeof (data_or_promise) === 'undefined') {
            data_or_promise = client.count_hour_since(hour, query)
            cache.set(source_id, query, timestamp, data_or_promise)
        }
        if (data_or_promise instanceof Promise) {
            data_or_promise
                .then(v => {
                    cache.set(source_id, query, timestamp, v)
                    if (!cancel_update) setValue(v)
                })
        } else {
            setValue(data_or_promise)
        }
        return () => { cancel_update = true }
    },
        [client]
    )

    return value
}