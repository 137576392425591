// https://github.com/rhashimoto/promise-throttle
// The exported function takes as input a function and returns a
// function returning a Promise that automatically delays calls to the
// original function such that the rate is kept below the specified
// number of calls in the specified number of milliseconds.
export const throttle = (f: Function, calls: number, milliseconds: number): Function => {
    let queue: {this: any, args: any[], resolve: Function, reject: Function}[] = []
    let complete: number[] = []
    let inflight: number = 0

    const processQueue = () => {
        // console.debug(`Throttle processing queue`)

        // Remove old complete entries.
        const now = Date.now()
        while (complete.length && complete[0] <= now - milliseconds)
            complete.shift()

        // Make calls from the queue that fit within the limit.
        while (queue.length && complete.length + inflight < calls) {
            const request = queue.shift()
            ++inflight

            if (request) {
                // Call the deferred function, fulfilling the wrapper Promise
                // with whatever results and logging the completion time.
                var p = f.apply(request.this, request.args)
                Promise.resolve(p).then((result) => {
                    request.resolve(result)
                }, (error) => {
                    request.reject(error)
                }).then(() => {
                    --inflight
                    complete.push(Date.now())

                    if (queue.length && complete.length === 1)
                        setTimeout(processQueue, milliseconds)
                });
            }
        }

        // console.debug(`Throttle queue=${queue.length}`)

        // Check the queue on the next expiration.
        if (queue.length && complete.length)
            setTimeout(processQueue, complete[0] + milliseconds - now)
    }

    return (...args: any) => {
        return new Promise((resolve, reject) => {
            queue.push({
                this: this,
                args,
                resolve,
                reject
            })

            processQueue()
        })
    }
}