const second = 1000
const minute = 60 * second
const hour = 60 * minute

const pad = function (num: number): string {
    const norm = Math.floor(Math.abs(num))
    return `${(norm < 10 ? '0' : '')}${norm}`
}

export class Time {
    static now = (): Time => new Time(new Date)

    static minutes_ago = (mins_ago = 0): Time => new Time(new Date().valueOf() - (mins_ago * minute))

    static hours_ago = (hrs_ago = 0): Time => new Time(new Date().valueOf() - (hrs_ago * hour))

    #value: Date

    constructor(date: Date | Time | number) {
        if (date instanceof Date) {
            this.#value = date
        } else if (date instanceof Time) {
            this.#value = date.value
        } else {
            this.#value = new Date(date)
        }
    }

    get value(): Date { return this.#value }

    get iso_utc(): string { return this.#value.toISOString() }

    get papertrail_format(): string {
        const d = this.#value
        return `${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getUTCMonth()]} ${d.getDate().toString().padStart(2, '0')} ${d.getUTCHours().toString().padStart(2, '0')}:${d.getUTCMinutes().toString().padStart(2, '0')}:${d.getUTCSeconds().toString().padStart(2, '0')}`
    }

    get time(): string {
        const d = this.#value
        return `${d.getUTCHours().toString().padStart(2, '0')}:${d.getUTCMinutes().toString().padStart(2, '0')}:${d.getUTCSeconds().toString().padStart(2, '0')}`
    }

    get iso_local(): string {
        // https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
        var tzo = -(this.#value.getTimezoneOffset()),
            dif = tzo >= 0 ? '+' : '-'
        return this.#value.getFullYear() +
            '-' + pad(this.#value.getMonth() + 1) +
            '-' + pad(this.#value.getDate()) +
            'T' + pad(this.#value.getHours()) +
            ':' + pad(this.#value.getMinutes()) +
            ':' + pad(this.#value.getSeconds()) +
            dif + pad(tzo / 60) +
            ':' + pad(tzo % 60);
    }

    get timestamp(): number { return Math.floor(this.#value.getTime() / 1000) }

    set_timestamp(v: number) { this.#value = new Date(v * 1000); return this }

    round_hour() {
        this.#value = new Date(`${this.#value.toISOString().substr(0, 13)}:00:00.000Z`)
        return this
    }
}
